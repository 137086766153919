<template>
  <div class="min-h-100 min-w-100 h-100 position-relative smithing-page">
    <div @click="collapsed = !collapsed" class="position-absolute top-0 end-0 m-3 z-index-100 cursor-pointer">
      <VTooltip>
        <img src="/img/icons/gear.png" style="width: 32px;" class="image-pixelated"/>
        <template #popper>
          Toggle full information
        </template>
      </VTooltip>
    </div>

    <div class="position-absolute m-xxl-3 smithing-dialogue cursor-pointer" :class="{'collapsed': !collapsed}">

      <div class="dialogue-box">
        <div class="dialogue-box-inner px-4 pt-4">
          <div class="messages" style="z-index: 99;">
            <transition-group name="messageList">
              <div class="message" v-for="m in messages" :key="m.id">
                <div class="d-inline-flex px-4 flex-row align-items-center justify-content-center bg-dark">
                  <template v-if="m.items">
                    <Item
                        v-for="(i, index) in m.items"
                        :key="index"
                        :item="{Item: i.item}"
                        :size="32"/>
                  </template>
                  {{ m.message }}
                </div>
              </div>
            </transition-group>
          </div>
          <div class="container" v-if="!collapsed">
            <div class="row">
              <div class="col-12 col-md-6 mb-3"
                   v-for="(zone, index) in smithingZone"
                   :key="index">
                <div class="smithing-zone">
                  <div class="ore">
                    <img :src="`/img/items/${zone.image}.png`" class="me-3 cursor-pointer"
                         @click="startSkill(zone.id)"
                         style="width: 64px; height: 64px;"
                         :alt="zone.image"/>
                  </div>
                  <div class="stats">
                    <img
                        :class="{'greyscale': currentHeat < zone.heat}"
                        src="/img/sprites/flame.png"/>
                    {{ zone.heat }}
                    <img src="/img/items/Clock.png" class="ms-3"/>
                    {{ timeForSkill(zone) }}s
                  </div>
                  <div class="d-flex flex-row smithing-requirements">
                    <Item :item="{Item: item.item}" v-for="(item, index) in zone.requires" :key="index"
                          :quantity="item.quantity"
                          :invalid-amount="hasEnoughItems(item)"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="dialogue-box-inner px-4 pt-4 d-flex flex-row flex-wrap justify-content-center">
              <div
                  v-for="(zone, index) in smithingZone"
                  :key="index">
                <img :src="`/img/items/${zone.image}.png`" class="ore me-3"
                     @click="startSkill(zone.id)"
                     :alt="zone.image"/>

                <div class="me-2" :class="{'greyscale': currentHeat < zone.heat}"><img src="/img/sprites/flame.png"/>
                  {{ zone.heat }}
                </div>
                <div><img src="/img/items/Clock.png"/> {{ timeForSkill(zone) }}s</div>
              </div>
            </div>
          </div>
        </div>
        <div class="dialogue-box-inner p-4">
          <div v-if="currentSkill && currentSkill.skill === 'smithing'">
            <SocketProgressBar :skill="currentSkill" v-if="currentSkill && currentSkill.skill === 'smithing'"/>
            <button @click="stopSkill" class="btn btn-primary w-100" v-if="currentSkill.skill === 'smithing'">stop
            </button>

          </div>
        </div>


        <div class="dialogue-bg"></div>
      </div>
    </div>


    <div class="modal bg-dark bg-opacity-75" :class="{'show fade d-block': this.forgeUpgrade}" tabindex="-1"
         @click="closeForgeUpgrade">
      <div class="modal-dialog bg-dark" @click.stop>
        <div class="modal-content bg-dark border-dark">
          <div class="modal-header border-0">
            <h5 class="modal-title text-white">Upgrade your forge</h5>
            <button type="button" class="btn-close text-white bg-white" data-bs-dismiss="modal"
                    aria-label="Close"
                    @click="closeForgeUpgrade"></button>
          </div>
          <div class="modal-body d-flex flex-column gap-2">
            <h3 class="text-white-50" v-if="nextForgeLevel <= 15">Resource cost for <strong>level {{
                nextForgeLevel
              }}</strong></h3>
            <h3 class="text-white-50" v-else>You are maxed</h3>
            <div class="d-flex flex-row" v-if="nextLevelForge">
              <Item :item="{Item: item.item}" v-for="(item, index) in nextLevelForge.recipe"
                    :quantity="item.quantity"
                    :invalid-amount="hasEnoughItems(item)"
                    :key="index"/>
            </div>

            <button class="btn btn-primary btn-bg-block btn-warning py-2 text-black-50" :disabled="hasEnoughTotalItems"
                    @click="upgradeForge" v-if="nextLevelForge">Upgrade
            </button>
          </div>
        </div>
      </div>
    </div>

    <img src="/img/smithing/sign.png" class="sign"/>
    <div class="smith-bg" :style="'--bg-image: url(' + forgeLevelBg + ')'"></div>
    <div class="upgrade-forge" v-if="nextForgeLevel <= 15">
      <button class="btn btn-primary btn-bg-block bg-warning text-dark" @click="openForgeUpgrade">Upgrade Forge</button>
    </div>
  </div>
</template>
<style lang="scss">

.upgrade-forge {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.smithing-dialogue.collapsed {

  .smithing-zone {
    display: grid;
    grid-template-areas: "item stats"
                        "item requires";

    .ore {
      grid-area: item;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .stats {
      grid-area: stats;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .smithing-requirements {
      grid-area: requires;
    }
  }
}

.ore > img, img.ore {
  max-width: 150px;
  width: 64px;
  image-rendering: pixelated;

  &:hover {
    filter: saturate(300%);
  }
}

.smithing-page {
}

.greyscale {
  filter: grayscale(100%);
}

.smith-bg {
  width: 100%;
  height: 100%;
  background-image: var(--bg-image);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.sign {
  position: absolute;
  bottom: 0;
  left: 20%;
}
</style>
<script>
// import ZoneBox from "@/components/ZoneBox";

import SocketProgressBar from "@/components/SocketProgressBar.vue";
import store from "@/store";
import {mapGetters} from "vuex";
import Item from "@/components/items/Item.vue";

export default {
  name: 'About',
  components: {Item, SocketProgressBar},
  // components: {ZoneBox},
  data() {
    return {
      store: store.state,
      collapsed: true,
      messages: [],
      forgeUpgrade: false,
    }
  },
  sockets: {
    'GAIN_XP': function (skill) {

      if (skill.skillName === 'smithing' && this.messages.length < 5) {
        this.messages.push({
          id: this.messages.length + Math.floor(Math.random() * 100),
          message: `+${skill.xp}xp`,
          items: skill.items
        });

        setTimeout(() => {
          this.messages.splice(0, 1);
        }, 3000);
      }
    },
  },
  computed: {
    smithingZone() {
      return this.zones && this.zones.filter(i => i.skill === 'smithing')
    },
    nextForgeLevel() {
      return this.character.forge_level + 1;
    },
    hasEnoughTotalItems() {
      let passed = true;

      this.nextLevelForge.recipe.map(i => {
        if (!this.hasEnoughItems(i))
          passed = false;
      })

      return passed;
    },
    nextLevelForge() {
      const currentLevel = this.character.forge_level;
      return this.zones && this.zones.find(i => i.id === (3000 + Number(currentLevel)));
    },
    forgeLevelBg() {
      if (this.character.forge_level < 5)
        return '/img/backgrounds/forge_0.png';
      else if (this.character.forge_level < 10)
        return '/img/backgrounds/forge_1.png';
      else if (this.character.forge_level < 15)
        return '/img/backgrounds/forge_2.png';
      else if(this.character.forge_level === 15)
        return '/img/backgrounds/forge_3.png';

      return '/img/backgrounds/forge_3.png';
    },
    ...mapGetters({
      currentSkill: "user/activeSkillZone",
      zones: "config/zones",
      currentHeat: "user/heat",
      inventory: "user/inventory",
      character: "user/character",
    })
  },
  methods: {
    hasEnoughItems(item) {
      const inventoryItem = this.inventory.find(s => s.item_id === item.item.id && s.amount >= (item.quantity || 1));

      return !inventoryItem;
    },
    openForgeUpgrade() {
      this.forgeUpgrade = true;
    },
    closeForgeUpgrade() {
      this.forgeUpgrade = false;
    },
    upgradeForge() {
      this.$socket.emit("auth:forge upgrade");
    },
    stopSkill() {
      this.$socket.emit("auth:stop skill");
    },
    startSkill(id) {
      this.$socket.emit("auth:start skill", id);
    },
    timeForSkill(zone) {
      const items = this.inventory.filter(i => i.equipped === true);

      const reducer = function (a, b) {
        if (typeof a === 'number') {
          return a + b.enchantment_strength
        }

        return a.enchantment_strength + b.enchantment_strength
      }


      const attrReducer = function (a, b) {
        if (typeof a === 'number') {
          return a + Number(b.value)
        }

        return Number(a.value) + Number(b.value)
      }

      let enchantments = [0];
      let timer = zone.timer;


      for (const item in items) {
        const i = items[item]

        for (const en in i.ItemEnchantments) {
          const enchantment = i.ItemEnchantments[en];

          if (enchantment.enchantment_name === `${zone.skill}Speed`) {
            enchantments.push(enchantment)
          }
        }
      }


      let attributes = [0];

      for (const item in items) {
        const i = items[item];

        for (const en in i.ItemAttributes) {
          const attr = i.ItemAttributes[en];

          if (attr.attribute === `${zone.skill}Speed`) {
            attributes.push(attr)
          }
        }
      }

      let attrReduction = attributes.reduce(attrReducer);
      let speedReduction = enchantments.reduce(reducer) + attrReduction;

      for (let i = 0; i < this.character.UserLevel[zone.skill + '_level']; i++) {
        if (i < 30)
          speedReduction += 1
        else if (i < 60)
          speedReduction += 0.5
        else
          speedReduction += 0.2
      }


      speedReduction += (this.character.forge_level * 1)


      if (speedReduction > 80)
        speedReduction = 80;

      return ((timer / 100 * (100 - speedReduction)) / 1000).toFixed(2);
    },
  }
}
</script>
