<template>
  <div class="progress-outer-bar mw-100">
    <div class="progress-bar bg-dark-progress-grad"
         :style="{
                      'animation-duration': timer / 1000 + 's',
                    }"
         ref="pb">
    </div>
  </div>
</template>
<style scoped lang="scss">

.progress-outer-bar {
  background: rgba(0, 0, 0, 0.1);
  height: 15px;
  z-index: 10;
}

.progress-bar {
  width: 0;
  height: 100%;
  //border-radius: 30px;
  &.animate {
    animation-name: animateProgressBar;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }

}


@keyframes animateProgressBar {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
</style>
<script>
import store from '../store'

export default {
  name: 'SocketProgressBar',
  data() {
    return {
      timer: null,
      store: store.state,
    }
  },
  props: {
    skill: {
      type: Object
    }
  },
  mounted() {
    const state_id = store.state.user.character.current_skill;

    if (this.skill.id === state_id) {
      const zone = store.state.config.zones.find(o => o.id === state_id);

      const lastZoneTimer = (new Date(store.state.user.character.updatedAt)).getTime() + zone.timer;

      const now = (new Date()).getTime();

      this.timer = (lastZoneTimer - now);



      this.$refs.pb.classList.remove('animate');
      void this.$refs.pb.offsetWidth;
      this.$refs.pb.classList.add('animate');
    }
  },
  sockets: {
    'STOP_MOB_BAR': function (id) {
      const startsWith = String(this.skill.id).startsWith('monster-combat');
      if (startsWith && `monster-combat-${id}` === this.skill.id) {
        this.started = false;
        this.$refs.pb.classList.remove('animate');
        void this.$refs.pb.offsetWidth;
      }
    },
    'STOP_SKILL': function () {
      this.started = false;
      this.$refs.pb.classList.remove('animate');
      void this.$refs.pb.offsetWidth;
    },
    'START_SKILL': function (skill) {

      if (skill.id === this.skill.id) {
        this.timer = skill.timer;

        this.$refs.pb.classList.remove('animate');
        void this.$refs.pb.offsetWidth;
        this.$refs.pb.classList.add('animate');
      }
    },
    'START_COMBAT_TIMER': function (skill) {
      if (skill.id === this.skill.id) {
        this.timer = skill.timer;

        this.$refs.pb.classList.remove('animate');
        void this.$refs.pb.offsetWidth;
        this.$refs.pb.classList.add('animate');
      }
    },
  }

}
</script>
